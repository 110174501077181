<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">食堂评分</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
<!--    主体部分-->
    <div style="width: 95%;margin-left: auto;margin-right: auto;background-color: white;border-radius: 5px;">
      <!--    选择年份，季度-->
      <div style="padding-top: 20px;margin-left: 10px">
        <!--      年份-->
        <el-date-picker
            size="small"
            v-model="year1"
            type="year"
            placeholder="请选择年份"
        />
        <!--      季度-->
        <el-select v-model="seljd" class="m-2" placeholder="请选择季度" size="small">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
        <el-button type="primary" size="small" @click="selanswernum()">查询</el-button>
      </div>
      <!--    展示问题-->
      <div style="margin-top: 20px;padding-bottom: 20px;">
        <div style="margin-left: 50px">
          <div v-for="(questions, index) in question" style="margin-bottom: 20px;border:3px solid #409EFF;padding-top: 10px;padding-left: 10px;width: 60%;border-radius: 15px;">
            <div>
              {{ index+1 + '、' }}{{ questions }}
            </div>
            <div style="margin-left: 20px;margin-top: 15px" v-for="(answer1, index1) in answer" >
              <div v-if="answer1.substr(0,1)*1 === index">
                {{ answer1.slice(2) }}
              </div>
            </div>
            <div v-if="index === 8" style="margin-left: 20px;width: 80%;margin-top: 20px">
              <div v-for="(otheryj1,index) in otheryj" style="margin-bottom: 10px">
                {{ index+1 + otheryj1 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/views/header";
import {setNowMonth2} from "@/utils/time";

export default {
  components: {Header},
  name: "StGrade",
  data() {
    return {
      year: '',
      jd: '',
      year1: '',
      question: [],
      answer: [],
      otheryj: [],
      seljd: '',
      options: [
        {
          value: '一',
          label: '一',
        },
        {
          value: '二',
          label: '二',
        },
        {
          value: '三',
          label: '三',
        },
        {
          value: '四',
          label: '四',
        }]
    }
  },
  created() {
    let nowMonth2 = setNowMonth2();
    let strings = nowMonth2.split("-");
    this.year = strings[0];
    this.year1 = this.year;
    if (strings[1] === '01' || strings[1] === '02' || strings[1] === '03') {
      this.jd = '一'
    }
    if (strings[1] === '04' || strings[1] === '05' || strings[1] === '06') {
      this.jd = '二'
    }
    if (strings[1] === '07' || strings[1] === '08' || strings[1] === '09') {
      this.jd = '三'
    }
    if (strings[1] === '10' || strings[1] === '11' || strings[1] === '12') {
      this.jd = '四'
    }
    this.seljd = this.jd;
    // 获取问题、答案
    this.axios.get('/headquartersQuestion/getqueanw', (response) => {
      this.question = response.obj;
    }, {
      jd: this.year+this.jd
    })
    // 获取问卷答案
    this.axios.get('/headpeoanswers/getAnswer', (response) => {
      this.answer = response.obj2;
      this.otheryj = response.obj
    }, {
      jd: this.year1 + this.seljd
    })
  },
  methods:{
    selanswernum(){
      this.axios.get('/headpeoanswers/getAnswer', (response) => {
        this.answer = response.obj2;
        this.otheryj = response.obj
      }, {
        jd: this.year1 + this.seljd
      })
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>